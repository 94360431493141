import React, { useState } from 'react';
import Sidebar from '../sidebar/sidebar';
import TopNav from '../topnav/TopNav';
import useLocalStorage from '../custom-hooks/useLocalStorage';
import './AccountContent.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';


const AccountContent = ({ content }) => {
    const navItems = [
        {
            label: "Profile Settings",
            path: "/manage-account/profile",
            icon: <i className="bi bi-person"></i>
        },
        // {
        //     label: "Contact Settings",
        //     path: "/manage-account/contact-info",
        //     icon: <i className="bi bi-house"></i>
        // },
        {
            label: "Security Settings",
            path: "/manage-account/security",
            icon: <i className="bi bi-shield-lock"></i>
        },
        // {
        //     label: "Subscriptions & Payments",
        //     path: "/manage-account/subscriptions",
        //     icon: <i className="bi bi-credit-card"></i>
        // },
        {
            label: "Notifications Settings",
            path: "/manage-account/notifications",
            icon: <i className="bi bi-bell"></i>
        },
        // {
        //     label: "Account Management",
        //     path: "/manage-account/account",
        //     icon: <i className="bi bi-gear"></i>
        // },
        {
            label: "Help & Support",
            path: "/manage-account/support",
            icon: <i className="bi bi-question-circle"></i>
        },

    ]
    const [sidebarOpen, setSidebarOpen] = useLocalStorage(true, true);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className={`main-page ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <TopNav toggleSidebar={toggleSidebar} displayTextHeader={false} />
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} navItems={navItems} />
            <div className="content-area">
                {content}
            </div>
        </div>
    )
}

export default AccountContent;