import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { isTokenValid } from "../../services/auth";
import { getUser, logout } from "../../services/account";


const AccountIndex = () => {
    const [user, setUser] = useState(null);
    const isAuthenticated = isTokenValid();

    const fetchUser = async () => {
        if (isAuthenticated) {
            const response = await getUser();
            setUser(response);
        }
    }

    useEffect(() => {
        fetchUser();
    }, [isAuthenticated])

    return (
        <div className="d-flex flex-column min-vh-100 bg-light">
            {/* Top Navigation Bar */}
            <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
                <div className="container">
                    <a className="navbar-brand text-primary fw-bold" href="/">
                        {isAuthenticated ? 'Manage my Account' : 'Echowavs Accounts'}
                    </a>
                    <div>
                        {isAuthenticated ? (
                            <span className="navbar-text text-success">
                                Welcome, {user?.username}!
                            </span>
                        ) : (
                            <>
                                <a href="/login" className="btn btn-outline-primary me-2">
                                    Log In
                                </a>
                                <a href="/signup" className="btn btn-primary">
                                    Sign Up
                                </a>
                            </>
                        )}
                    </div>
                </div>
            </nav>

            {/* Main Content */}
            <main className="flex-grow-1 d-flex align-items-center">
                <div className="container text-center">
                    <h1 className="display-4 fw-bold text-primary mb-3">
                        {isAuthenticated ? "Welcome Back!" : "Manage Your Account Seamlessly"}
                    </h1>
                    <p className="lead text-secondary">
                        {isAuthenticated
                            ? "Access your profile, settings, and more."
                            : "Log in or sign up to start managing your Echowavs account."}
                    </p>

                    {isAuthenticated ? (
                        <div className="mt-4">
                            <a href="/manage-account/profile" className="btn btn-primary btn-lg me-3">
                                Manage Account
                            </a>
                            <a href="#" onClick={logout} className="btn btn-outline-danger btn-lg">
                                Log Out
                            </a>
                        </div>
                    ) : (
                        <div className="mt-4">
                            <a href="/signin" className="btn btn-primary btn-lg me-3">
                                Log In
                            </a>
                            <a href="/signup" className="btn btn-outline-secondary btn-lg">
                                Sign Up
                            </a>
                        </div>
                    )}
                </div>
            </main>

            {/* Footer */}
            <footer className="bg-white text-center py-3 border-top">
                <p className="text-muted mb-0">© 2024 Echowavs. All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default AccountIndex;
