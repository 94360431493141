import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { encryptData } from './encryptionUtils';
import { setCookie } from './cookieUtils';


export default async function getFingerprint() {
    // Check if device_id already exists in localStorage
    let encryptedFingerprint = localStorage.getItem('device_id');

    // If device_id is already stored, return it
    if (encryptedFingerprint) {
        return encryptedFingerprint;
    }

    // Load FingerprintJS and generate fingerprint
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId;

    // Encrypt and store in localStorage
    encryptedFingerprint = await encryptData(fingerprint);
    setCookie('device_id', encryptedFingerprint);

    return encryptedFingerprint;
}

