import React, { useState } from 'react';
import './Modal.css';
import Loader from '../loader/Loader';

const Modal = ({ isOpen, onClose, title, children, buttons = [] }) => {
    const [loading, setLoading] = useState(false);
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            {loading && <Loader/>}
            <div className="modal-container">
                <div className="modal-header">
                    <h2 className='p-0 text-white'>{title}</h2>
                    <button className="close-btn" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer">
                    {buttons.length > 0 ? (
                        buttons.map((button, index) => (
                            <button
                                key={index}
                                className={`btn me-2 custom-btn ${button.type ? `btn-${button.type}` : ''}`}
                                style={button.style}
                                onClick={async () => {
                                    setLoading(true);
                                    if (button.onClick) {
                                        await button.onClick(); // Await the button action
                                        onClose(); // Close the modal after action completes
                                    }
                                    setLoading(false);
                                }}
                            >
                                {button.label}
                            </button>
                        ))
                    ) : (
                        <>
                            <button className="btn me-2 custom-btn btn-danger" onClick={onClose}>Cancel</button>
                            <button className="btn me-2 custom-btn btn-secondary" onClick={onClose}>Ok</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;
