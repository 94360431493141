import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/account/AuthStyles.css';
import { useLogin } from '../../services/auth';

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [requires2FA, setRequires2FA] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const { handleLogin, handle2FAVerification, resend2FACode } = useLogin();
    const [countDown, setCountDown] = useState(5);
    const [active, setActive] = useState(false);

    useEffect(() => {
        let date = new Date().setMinutes(5).toString();
    }, [])
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await handleLogin(username, password, setError, setSuccess, setRequires2FA);
        } catch (error) {
            throw error;

        } finally {
            setLoading(false);
        }

    }

    const handleVerify2FA = async (e) => {
        e.preventDefault();
        setLoading(true);
        await handle2FAVerification(verificationCode, setError);
        setVerificationCode('');
        setLoading(false);
    };

    const handleResendCode = async () => {
        setLoading(true);
        await resend2FACode(setError, setSuccess);
        setLoading(false);
    }

    return (
        <div className="auth-container d-flex justify-content-center align-items-center vh-100">
            <div className="card login-card">
                {loading && <div className="loading-overlay">
                    <div className="loader"></div>
                </div>}
                <div className='card-body login-card-body'>
                    {/* Left Side  Logo and welcome text*/}
                    <div className='col-md-6 welcome-section'>
                        <div className='text-center'>
                            <img
                                src="/assets/img/echowavs-no-bg.png"
                                alt="Company Logo"
                                className="img-fluid mx-auto d-block mb-3"
                                style={{ maxWidth: '200px' }}
                            />
                            <div className='welcome-text'>
                                <h3>Welcome to our platform</h3>
                                <p>
                                    We're excited to have you here. Please Login to continue exploring our services
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Right Side Form */}
                    <div className='col-md-6 login-section'>
                        <div>
                            {requires2FA ? (
                                <>
                                    <h4 className='text-start fs-1 text-login mb-4'>2-Step Verification</h4>
                                    <form onSubmit={handleVerify2FA}>
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                onChange={(e) => setVerificationCode(e.target.value)}
                                                value={verificationCode}
                                                className="form-control"
                                                placeholder="Enter verification Code Sent to your email"
                                                required
                                            />
                                            <p className='text-end text-primary' style={{ cursor: "pointer" }} onClick={handleResendCode}>Resend Code</p>
                                            {error && <p style={{ color: 'red', marginTop: -10 + "px" }}>{error}</p>}
                                            {success && <p style={{ color: 'green', marginTop: -10 + "px" }}>{success}</p>}
                                            <div className='continue-btn mt-5'>
                                                <button type="submit" className="btn btn-primary">Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                </>
                            ) :
                                <>
                                    <h4 className='text-start fs-1 text-login mb-4'>Login</h4>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                onChange={(e) => setUsername(e.target.value)}
                                                value={username}
                                                className="form-control"
                                                placeholder="Username"
                                                required
                                            />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input
                                                type="password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                                className="form-control"
                                                placeholder="Password"
                                                required
                                            />
                                        </div>
                                        <p className='text-end text-primary' style={{ marginTop: -20 + "px", cursor: 'pointer' }} onClick={() => window.location.href = '/reset_password/'}>Forgot Password?</p>
                                        {error && <p style={{ color: 'red', marginTop: -10 + "px" }} className='text-center'>{'Invalid Username or Password'}</p>}
                                        <div className='continue-btn mt-5'>
                                            <button type="submit" className="btn btn-primary">Sign In</button>
                                        </div>
                                    </form>
                                </>}
                        </div>
                        <p className="text-end signin-link">
                            New Here? <a href="/signup" className="text text-danger">Create one</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
