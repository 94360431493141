import axios from 'axios';
import { decryptData } from '../utils/encryptionUtils';
import { getCookie } from '../utils/cookieUtils';

const API = axios.create({
    baseURL: 'https://api.echowavs.com.ng',
    headers: {
        'Content-Type': 'application/json',
    },
});

API.interceptors.request.use(config => {
    const token = getCookie('access_token');
    const decryptToken = token ? decryptData(token) : null;
    if (decryptToken) {
        config.headers['Authorization'] = `Bearer ${decryptToken}`;
    }
    return config;
});


export default API;
