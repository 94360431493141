import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Toast = ({ message, onClose, successMessage = true }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            onClose();
        }, 3000); // Auto-hide after 3 seconds

        return () => clearTimeout(timer); // Cleanup the timer
    }, [onClose]);

    if (!visible) {
        return null; // Don't render the toast if not visible
    }

    return (
        <div
            className={`toast show position-fixed top-0 end-0 m-3`}
            style={{ minWidth: '250px', zIndex: 1050 }}
        >
            <div className={`toast-header ${successMessage ? 'bg-primary' : 'bg-danger'} text-white`}>
                <strong className="me-auto">Notification</strong>
                <button
                    type="button"
                    className="btn-close btn-close-white"
                    aria-label="Close"
                    onClick={() => {
                        setVisible(false);
                        onClose();
                    }}
                ></button>
            </div>
            <div className="toast-body">
                {message}
            </div>
        </div>
    );
};

export default Toast;
