import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/account/ManageAccount.css';
import AccountContent from '../../components/contents/AccountContent';
import { getAllCountries, getAllStateCountry, getUser, managePersonalInfo, manageAddressInfo, manageProfilePic } from '../../services/account';
import Loader from '../../components/loader/Loader';
import { getAllCountryCodes } from '../../services/account';
import Toast from '../../components/toast/toast';

const AccountProfile = () => {
    const [loading, setLoading] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [userDetail, setUserDetail] = useState(null);
    const [isEditing, setIsEditing] = useState({
        profile_info: false,
        address_info: false,
    });
    const [profilePic, setProfilePic] = useState('/assets/img/echowavs-logo.png');
    const [allCountryCodes, setAllCountryCodes] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [allStateCountry, setAllStateCountry] = useState([]);
    const [response, setResponse] = useState('');

    const handleToastClose = () => setIsToastOpen(false);

    const [data, setData] = useState({
        id: '',
        username: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        country_code: '',
        country: '',
        state: '',
        line: '',
        joined_at: '',
        updated_at: '',
        active: false
    });

    const fetchUser = async () => {
        try {
            const res = await getUser();
            setUserDetail(res);

            setProfilePic(res.profile_pic || '/assets/img/echowavs-logo.png');
            setData({
                id: res.id,
                username: res.username,
                first_name: res.first_name,
                last_name: res.last_name,
                phone_number: res.phone_number,
                email: res.email,
                country_code: res.country_code,
                country: res.country,
                state: res.state,
                line: res.line,
                joined_at: res.created_at,
                updated_at: res.updated_at,
                active: res.is_active
            });
        } catch (error) {
            console.error("Error fetching user:", error);
        }
    };

    const fetchAllCountryCodes = async () => {
        try {
            const res = getAllCountryCodes();
            setAllCountryCodes((await res).data.results);
        } catch (error) {
            setAllCountryCodes([]);
            console.log(error);
        }
    }
    const fetchAllCountries = async () => {
        try {
            const res = await getAllCountries();
            setAllCountries(res.data.results);
        } catch (error) {
            setAllCountries([]);

        }
    }

    const fetchAllStateCountry = async (country) => {
        try {
            const res = await getAllStateCountry(country);
            setAllStateCountry(res.data);
        } catch (error) {
            console.log(error);

            setAllStateCountry([]);
        }
    }

    useEffect(() => {
        fetchAllCountryCodes();
        fetchUser();
        fetchAllCountries();
    }, []);

    useEffect(() => {
        fetchAllStateCountry(data.country);
    }, [data.country])

    const handleProfileEditToggle = () => setIsEditing((prevData) => ({
        ...prevData,
        profile_info: !isEditing.profile_info
    }));

    const handleAddressEditToggle = () => setIsEditing((prevData) => ({
        ...prevData,
        address_info: !isEditing.address_info,
    }))

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (e) => {
        const selectedCountryCode = e.target.value
        setData((prevData) => ({
            ...prevData,
            country_code: selectedCountryCode
        }))
    }

    const handleSelectCountryChange = (e) => {
        const selectedCountry = e.target.value
        setData((prevData) => ({
            ...prevData,
            country: selectedCountry
        }))
    }

    const handleSave = async () => {
        setLoading(true);
        try {

            const res = await managePersonalInfo(data?.id, data);
            setUserDetail(res);
            setIsEditing((prevData) => ({
                ...prevData,
                profile_info: false
            }));
            setResponse(<span className='text-success'>{res.message}</span>)
            setIsToastOpen(true);
        } catch (error) {
            setResponse(<span className='text-danger'>{error.error || "An Error Ocured, Please try again!."}</span>)
            setIsToastOpen(true);
            setIsEditing((prevData) => ({
                ...prevData,
                profile_info: true
            }));
            console.log(error);

        } finally {
            setLoading(false);
        }
    };

    const handleAddressSave = async () => {
        setLoading(true);
        try {

            const res = await manageAddressInfo(data?.id, data);
            setUserDetail(res);
            setIsEditing((prevData) => ({
                ...prevData,
                address_info: false
            }));
            setResponse(<span className='text-success'>{res.message}</span>)
            setIsToastOpen(true);
        } catch (error) {
            setResponse(<span className='text-danger'>{error.error || "An Error Ocured, Please try again!."}</span>)
            setIsToastOpen(true);
            setIsEditing((prevData) => ({
                ...prevData,
                address_info: true
            }));
        } finally {
            setLoading(false);
        }
    }

    const handleProfilePicChange = async (e) => {
        const file = e.target.files[0];
        if (file) {

            try {
                const res = await manageProfilePic(userDetail?.id, file); // Pass only the file
                setUserDetail((prevData) => ({
                    ...prevData,
                    profile_pic: res.profile_pic,
                }));

                // Preview the uploaded file on the frontend
                setProfilePic(URL.createObjectURL(file));
            } catch (error) {
                setResponse(<span className='text-danger'>{error.error || "An Error Ocured, Please try again!."}</span>)
                setIsToastOpen(true);
                console.error(error); // Debugging errors
            }
        }
    };

    useEffect(() => {
        return () => {
            if (profilePic) URL.revokeObjectURL(profilePic);
        };
    }, [profilePic]);

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setData((prevData) => ({
            ...prevData,
            state: selectedState
        }))
    }

    function formatDateTime(dateString) {
        const date = new Date(dateString);

        // Get individual date components
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' }); // "Nov"
        const year = date.getFullYear();

        // Get individual time components
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // Format as "11-Nov-2024 07:20:03"
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <AccountContent
            content={
                <div className="main-container manage-account-wrapper py-5">
                    {loading && <Loader />}
                    {isToastOpen && (
                        <Toast onClose={handleToastClose} message={response}>
                        </Toast>
                    )}
                    {/* Personal Information */}
                    <div className="card shadow-lg border-0 mb-3">
                        <div className="card-body">
                            <div className="text-center mb-4">
                                <label htmlFor="uploadProfilePic" className="profile-pic-container">
                                    <img
                                        src={profilePic}
                                        alt="Profile"
                                        className="rounded-circle profile-pic"
                                    />
                                    <input
                                        id="uploadProfilePic"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleProfilePicChange}
                                        hidden
                                    />
                                    <div className="overlay">
                                        <span className="text-white">Edit</span>
                                    </div>
                                </label>
                                <h5 className="mt-3">{`${userDetail?.first_name || ''} ${userDetail?.last_name || ''}`}</h5>
                                <p className="text-muted">{userDetail?.email}</p>
                            </div>

                            <form>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                id="username"
                                                name="username"
                                                className="form-control"
                                                placeholder="Username"
                                                value={data.username}
                                                onChange={handleInputChange}
                                                disabled={!isEditing.profile_info}
                                            />
                                            <label htmlFor="username">Username</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <select className='form-control' disabled={!isEditing.profile_info} value={data.country_code} onChange={handleSelectChange}>
                                                {
                                                    allCountryCodes && allCountryCodes.length ?
                                                        allCountryCodes.map((code) => (
                                                            <option value={code.id}>{code.code} ({code.country})</option>
                                                        ))
                                                        : null
                                                }
                                            </select>
                                            <label htmlFor="username">Country Code</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                id="phone_number"
                                                name="phone_number"
                                                className="form-control"
                                                placeholder="Phone Number"
                                                value={data.phone_number}
                                                onChange={handleInputChange}
                                                disabled={!isEditing.profile_info}
                                            />
                                            <label htmlFor="phone_number">Phone Number</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email Address"
                                                value={data.email}
                                                onChange={handleInputChange}
                                                disabled={!isEditing.profile_info}
                                            />
                                            <label htmlFor="phone_number">Email Address</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                id="first_name"
                                                name="first_name"
                                                className="form-control"
                                                placeholder="First Name"
                                                value={data.first_name}
                                                onChange={handleInputChange}
                                                disabled={!isEditing.profile_info}
                                            />
                                            <label htmlFor="first_name">First Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                id="last_name"
                                                name="last_name"
                                                className="form-control"
                                                placeholder="Last Name"
                                                value={data.last_name}
                                                onChange={handleInputChange}
                                                disabled={!isEditing.profile_info}
                                            />
                                            <label htmlFor="last_name">Last Name</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                    {isEditing.profile_info ? (
                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-success me-2"
                                                onClick={handleSave}
                                            >
                                                Save Changes
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={handleProfileEditToggle}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleProfileEditToggle}
                                        >
                                            Edit Profile
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Address Information */}
                    <div className="card shadow-lg border-0 mb-3">
                        <div className="card-body">
                            <form>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <select className='form-control' disabled={!isEditing.address_info} value={data.country} onChange={handleSelectCountryChange}>
                                                {
                                                    allCountries && allCountries.length ?
                                                        allCountries.map((country) => (
                                                            <option value={country.id}>{country.name}</option>
                                                        ))
                                                        : null
                                                }
                                            </select>
                                            <label htmlFor="username">Country</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-3">
                                            <select className='form-control' disabled={!isEditing.address_info} value={data.state} onChange={handleStateChange}>
                                                {
                                                    allStateCountry && allStateCountry.length ?
                                                        allStateCountry.map((state) => (
                                                            <option value={state.id}>{state.state}</option>
                                                        ))
                                                        : null
                                                }
                                            </select>
                                            <label htmlFor="phone_number">State</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="">
                                        <div className="form-floating mb-3">
                                            <input
                                                type="text"
                                                id="line"
                                                name="line"
                                                className="form-control"
                                                placeholder="Address Line 1"
                                                value={data.line}
                                                onChange={handleInputChange}
                                                disabled={!isEditing.address_info}
                                            />
                                            <label htmlFor="line">Address Line 1</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    {isEditing.address_info ? (
                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-success me-2"
                                                onClick={handleAddressSave}
                                            >
                                                Save Changes
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={handleAddressEditToggle}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddressEditToggle}
                                        >
                                            Edit Address
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Other Information */}
                    <div className="card shadow-lg border-0">
                        <div className="card-body text-start" style={{ color: "grey" }}>
                            <h5>Account Status: {data.active ? <span className='text-success'>active</span> : <span className='text-dnager'>inactive</span>}</h5>
                            <h5>Joined On {formatDateTime(data.joined_at)}</h5>
                            <h5>Updated At {formatDateTime(data.updated_at)}</h5>
                        </div>
                    </div>
                </div>
            }
        />
    )
};
export default AccountProfile;
