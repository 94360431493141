import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/account/AuthStyles.css';
import { addressInfo, firstLastName, getAllCountries, getAllCountryCodes, getAllStateCountry, getUser, passwordV, phoneNumberV, signup, validateEmail, validatePhone, validateUsername, verifyOtp } from '../../services/account';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import validatePassword from '../../utils/passwordValidate';
import { decryptData, encryptData } from '../../utils/encryptionUtils';
import { getCookie, setCookie } from '../../utils/cookieUtils';

const SignUpPage = () => {
    const [searchParams] = useSearchParams();
    const inviteCode = searchParams.get('inviteCode');
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirect');
    const [step, setStep] = useState(0);
    const [animate, setAnimate] = useState(false); // new animate state
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [addressLine, setAddressLine] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const encryptedDeviceId = getCookie('device_id');
    const decryptDeviceID = () => setDeviceId(decryptData(encryptedDeviceId));
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [response, setResponse] = useState({
        username: '',
        email: '',
        phoneNumber: '',
        state: '',
    });
    const [loading, setLoading] = useState(false);
    const [validatedData, setValidatedData] = useState({
        username: false,
        email: false,
        phoneNumber: false,
    })
    useEffect(() => {
        decryptDeviceID();
    }, [])

    const fetchAllCountries = async () => {
        try {
            const response = await getAllCountries();
            setCountries(response.data.results);
        } catch (error) {
            setError(error.error);
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }
    }
    const fetchCountryCodes = async () => {
        try {
            const response = await getAllCountryCodes();
            setCountryCodes(response.data.results);
        } catch (error) {
            setError(email.error);
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }
    }
    const fetchStates = async (country) => {
        try {
            const response = await getAllStateCountry(country);
            setStates(response.data);
            console.log(response);

        } catch (error) {
            setError(error.error);
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }
    }

    const handleUsernameValidate = async (username) => {
        if (username !== '') {
            try {
                const response = await validateUsername(username);
                setValidatedData((prev) => ({
                    ...prev,
                    username: true
                }));
                setResponse((prev) => ({
                    ...prev,
                    username: response.data.message,
                }));
            } catch (error) {
                setValidatedData((prev) => ({
                    ...prev,
                    username: false
                }));
                setResponse((prev) => ({
                    ...prev,
                    username: error.error,
                }));
            }

        } else {
            setValidatedData((prev) => ({
                ...prev,
                username: false,
            }))
        }
    }

    const handleEmailValidate = async (email) => {

        if (email !== '') {
            try {
                const response = await validateEmail(email);
                setValidatedData((prev) => ({
                    ...prev,
                    email: true
                }));
                setResponse((prev) => ({
                    ...prev,
                    email: response.data.message
                }))
            } catch (error) {
                setValidatedData((prev) => ({
                    ...prev,
                    email: false
                }));
                setResponse((prev) => ({
                    ...prev,
                    email: error.error
                }))
            }
        } else {
            setValidatedData((prev) => ({
                ...prev,
                email: false,
            }))
        }
    }

    const handlePhoneValidate = async (phoneNumber) => {
        if (phoneNumber !== '') {
            try {
                const response = await validatePhone(phoneNumber);
                setValidatedData((prev) => ({
                    ...prev,
                    phoneNumber: true
                }));
                setResponse((prev) => ({
                    ...prev,
                    phoneNumber: response.data.message,
                }));
            } catch (error) {
                setValidatedData((prev) => ({
                    ...prev,
                    phoneNumber: false
                }));
                setResponse((prev) => ({
                    ...prev,
                    phoneNumber: error.error,
                }));
            }

        } else {
            setValidatedData((prev) => ({
                ...prev,
                phoneNumber: false,
            }))
        }
    }

    useEffect(() => {
        handleUsernameValidate(username);
        handleEmailValidate(email);
        handlePhoneValidate(phoneNumber);
    }, [username, email, phoneNumber]);

    useEffect(() => {
        fetchAllCountries();
        fetchCountryCodes();
    }, [])

    useEffect(() => {
        fetchStates(selectedCountry);
    }, [selectedCountry])

    const handleSendOtp = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await signup(username, email, "verify_email", inviteCode);
            setSuccess(response.message);
            console.log(response.messsage);
            setAnimate(true); // trigger animation
            setTimeout(() => {
                setStep(1); // Move to OTP step
                setAnimate(false); // reset animation
            }, 500); // match duration to CSS transition
        } catch (err) {
            setError(err.error);
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }
        setLoading(false);
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await verifyOtp(email, otp, "verify_otp", { username });
            console.log(response);
            setSuccess(response.message);
            setAnimate(true); // trigger animation
            setTimeout(() => {
                setStep(2); // Registration complete step
                setAnimate(false); // reset animation
            }, 500); // match duration to CSS transition
        } catch (err) {
            setError(err.error);
            setOtp('');
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }
        setLoading(false);
    };

    const handleFirstLastName = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await firstLastName(firstName, lastName, 'first_last_name', { username, email });
            setSuccess(res.message);
            setAnimate(true); // trigger animation
            setTimeout(() => {
                setStep(3); // Registration complete step
                setAnimate(false); // reset animation
            }, 500); // match duration to CSS transition
            console.log(res)
        } catch (error) {
            setError(error.error);
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }

        setLoading(false);
    }

    const handleAddressInfo = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await addressInfo(selectedCountry, selectedState, addressLine, 'address_info', { username, email, first_name: firstName, last_name: lastName });
            setSuccess(res.message);
            setAnimate(true); // trigger animation
            setTimeout(() => {
                setStep(4); // Registration complete step
                setAnimate(false); // reset animation
            }, 500); // match duration to CSS transition
        } catch (error) {
            setError(error.error);
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }

        setLoading(false);
    }

    const handlePhoneNumber = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await phoneNumberV(selectedCountryCode, phoneNumber, 'phone_number_info', {
                username, email, first_name: firstName,
                last_name: lastName, country: selectedCountry, state: selectedState, line: addressLine
            });
            setSuccess(res.message);
            setAnimate(true); // trigger animation
            setTimeout(() => {
                setStep(5); // Registration complete step
                setAnimate(false); // reset animation
            }, 500); // match duration to CSS transition

        } catch (error) {
            setError(error.error);
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds

        }

        setLoading(false);
    }

    const handlePassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await passwordV(password, confirmPassword, 'password', inviteCode, deviceId, {
                username: username, email: email, first_name: firstName,
                last_name: lastName, country: selectedCountry,
                state: selectedState, line: addressLine, country_code: selectedCountryCode,
                phone_number: phoneNumber
            });
            const encryptedRefreshToken = encryptData(res.token.refresh);
            const encryptedAccessToken = encryptData(res.token.access);
            setCookie('refresh_token', encryptedRefreshToken);
            setCookie('access_token', encryptedAccessToken);
            window.location.href = redirectUrl || 'https://echowavs.com.ng';
        } catch (error) {
            console.log(error);

            setError(error.error || 'An Error Occured');
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 30000); // Timeout duration in milliseconds
        }
        setLoading(false);
    }

    const passwordValidation = validatePassword(password);

    return (
        <div className="auth-container d-flex justify-content-center align-items-center vh-100">

            <div className="card login-card">
                {
                    step === 0 ? null :
                        <div className='text-start m-0 text-primary btn-back-responsive' onClick={() => setStep(step - 1)}>
                            <FaArrowLeft size={15} />
                            <span> Back</span>
                        </div>
                }
                {loading && <div className="loading-overlay"><div className="loader"></div></div>}
                <div className='card-body login-card-body'>
                    <div className='col-md-6 welcome-section'>
                        <div className='text-center'>
                            <img src="/assets/img/echowavs-no-bg.png" alt="Company Logo" className="img-fluid mx-auto d-block mb-3" style={{ maxWidth: '200px' }} />
                            <div className='welcome-text'>
                                <h3>Welcome to our platform</h3>
                                <p>We're excited to have you here. Please sign up to continue exploring our services</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 login-section'>
                        <h4 className='text-start fs-1 text-login mb-4'>SignUp</h4>

                        <div className={`slide ${step === 0 && animate ? 'slide-enter' : ''} ${step === 1 && animate ? 'slide-exit' : ''}`}>
                            {step === 0 && (
                                <>
                                    <form onSubmit={handleSendOtp}>
                                        <div className='form-group mb-3'>
                                            <input type="text" className={`form-control`} placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                        </div>
                                        <p className={`float-start mb-2 ${validatedData.username && username !== '' ? 'text-success' : 'text-danger'}`} style={{ marginTop: -15 + "px" }}>{response.username ? response.username : null}</p>
                                        <div className='form-group mb-3'>
                                            <input type="email" className={`form-control`} placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <p className={`text-start mb-2 ${validatedData.email && email !== '' ? 'text-success' : 'text-danger'}`} style={{ marginTop: -15 + "px" }}>{response.email !== '' ? response.email : null}</p>
                                        <p className='text-danger text-center'>{error ? error : null}</p>
                                        <div className='continue-btn mt-5'>
                                            <button type="submit" className="btn btn-primary btn-continue" disabled={!validatedData.username && !validatedData.email}>Verify Email...</button>
                                        </div>
                                    </form>
                                    <p className="text-end signin-link">Already have an account? <a href="/signin" style={{ color: "red" }}>Login</a></p>
                                </>
                            )}
                        </div>

                        <div className={`slide ${step === 1 && animate ? 'slide-enter' : ''} ${step === 0 && animate ? 'slide-exit' : ''}`}>
                            {step === 1 && (
                                <form onSubmit={handleVerifyOtp}>
                                    <div className='form-group mb-3'>
                                        <input type="text" className="form-control mb-3" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                    </div>
                                    <p className='text-end echowavs-color-orange'>Didn't Receive code? <a href='#' onClick={handleSendOtp}>Resend</a></p>
                                    <p className='text-danger text-center'>{error ? error : null}</p>
                                    <div className='continue-btn mt-5'>
                                        <button className='btn btn-primary btn-back' onClick={() => setStep(step - 1)}>
                                            <FaArrowLeft size={15} />
                                            <span> Back</span>
                                        </button>
                                        <button type="submit" className="btn btn-primary float-end">
                                            <span>Verify... </span>
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div className={`slide ${step === 2 && animate ? 'slide-enter' : ''} ${step === 0 && animate ? 'slide-exit' : ''}`}>
                            {step === 2 && (
                                <form onSubmit={handleFirstLastName}>
                                    <div className='form-group mb-3'>
                                        <input type="text" className="form-control mb-3" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                    <div className='form-group mb-3'>
                                        <input type="text" className="form-control mb-3" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                    <p className='text-danger text-center'>{error ? error : null}</p>
                                    <div className='continue-btn mt-5'>
                                        <button className='btn btn-primary btn-back' onClick={() => setStep(step - 1)}>
                                            <FaArrowLeft size={15} />
                                            <span> Back</span>
                                        </button>
                                        <button type="submit" className="btn btn-primary float-end">Continue</button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div className={`slide ${step === 3 && animate ? 'slide-enter' : ''} ${step === 0 && animate ? 'slide-exit' : ''}`}>
                            {step === 3 && (
                                <form onSubmit={handleAddressInfo}>
                                    <div className='form-group mb-3'>
                                        <label className='text-start mb-1'>Address</label>
                                        <select className='form-control' value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
                                            <option value=''>Select Country</option>
                                            {
                                                countries && countries.length ?
                                                    countries.map((country, index) => (
                                                        <option key={index} value={country.id}>{country.name}</option>
                                                    )) : null
                                            }
                                        </select>
                                    </div>
                                    <div className='form-group mb-3'>
                                        <select className='form-control' value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                                            <option value=''>State</option>
                                            {
                                                states && states.length ?
                                                    states.map((state, index) => (
                                                        <option key={index} value={state.id}>{state.state}</option>
                                                    )) : null
                                            }
                                        </select>
                                    </div>
                                    <div className='form-group mb-3'>
                                        <input type="text" className="form-control mb-3" placeholder="Address Line" value={addressLine} onChange={(e) => setAddressLine(e.target.value)} />
                                    </div>
                                    <p className='text-danger text-center'>{error ? error : null}</p>
                                    <div className='continue-btn mt-5'>
                                        <button className='btn btn-primary btn-back' onClick={() => setStep(step - 1)}>
                                            <FaArrowLeft size={15} />
                                            <span> Back</span>
                                        </button>
                                        <button type="submit" className="btn btn-primary float-end">Continue</button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div className={`slide ${step === 4 && animate ? 'slide-enter' : ''} ${step === 0 && animate ? 'slide-exit' : ''}`}>
                            {step === 4 && (
                                <form onSubmit={handlePhoneNumber}>
                                    <div className='form-group mb-3'>
                                        <select className='form-control' value={selectedCountryCode} onChange={(e) => setSelectedCountryCode(e.target.value)}>
                                            <option value=''>Country Code</option>
                                            {
                                                countryCodes && countryCodes.length ?
                                                    countryCodes.map((code, index) => (
                                                        <option key={index} value={code.id}>({code.code}) {code.name}</option>
                                                    )) : null
                                            }

                                        </select>
                                    </div>
                                    <div className='form-group mb-3'>
                                        <input type="text" className="form-control mb-3" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                    <p className={`text-start ${validatedData.phoneNumber && phoneNumber !== '' ? 'text-success' : 'text-danger'}`} style={{ marginTop: -15 + "px" }}>{response.phoneNumber !== '' ? response.phoneNumber : null}</p>
                                    <p className='text-danger text-center'>{error ? error : null}</p>
                                    <div className='continue-btn mt-5'>
                                        <button className='btn btn-primary btn-back' onClick={() => setStep(step - 1)}>
                                            <FaArrowLeft size={15} />
                                            <span> Back</span>
                                        </button>
                                        <button type="submit" className="btn btn-primary float-end">Continue</button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div className={`slide ${step === 5 && animate ? 'slide-enter' : ''} ${step === 5 && animate ? 'slide-exit' : ''}`}>
                            {step === 5 && (
                                <form onSubmit={handlePassword}>
                                    <div className='form-group mb-3'>
                                        <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <p className='text-start m-0 password-description' style={{ color: 'grey', marginTop: -20 + "px" }}>
                                        <small className={`${passwordValidation.minLengthCheck ? 'text-success' : 'text-danger'}`}>At least 8 characters</small> *
                                        <small className={`${passwordValidation.uppercaseCheck ? 'text-success' : 'text-danger'}`}>At least one uppercase</small> *
                                        <small className={`${passwordValidation.lowercaseCheck ? 'text-success' : 'text-danger'}`}>At least one lowercase</small> *
                                        <small className={`${passwordValidation.digitCheck ? 'text-success' : 'text-danger'}`}>At least one digit</small> *
                                        <small className={`${passwordValidation.specialCharCheck ? 'text-success' : 'text-danger'}`}>At least one special character</small> *
                                        <small className={`${passwordValidation.sequenceCheck ? 'text-success' : 'text-danger'}`}>Cannot contain common sequences (e.g., 123, abc)</small>
                                    </p>
                                    <div className='form-group mb-3'>
                                        <input type="password" className="form-control mb-3" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </div>
                                    <p className='text-danger text-center'>{error ? (
                                        <>
                                            {error} <a href='/signup'>Create your Account Here</a>
                                        </>
                                    ) : null}</p>
                                    <div className='continue-btn mt-5'>
                                        <button className='btn btn-primary btn-back' onClick={() => setStep(step - 1)}>
                                            <FaArrowLeft size={15} />
                                            <span> Back</span>
                                        </button>
                                        <button type="submit" className="btn btn-primary float-end">Sign Up</button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
