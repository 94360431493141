import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './pages/account/LoginPage';
import SignUpPage from './pages/account/SignUpPage';
import AccontProfile from './pages/account/AcctProfileSettings';
import SecuritySettings from './pages/account/AcctSecuritySettings';
import { ProtectedRoute } from './services/auth';
import AcctSupport from './pages/account/AcctSuport';
import ResetPassword from './pages/account/ResetPassword';
import NotificationSettings from './pages/account/AcctNotificationSettings';
import AccountIndex from './pages/account/AccountIndex';


const AppRoutes = () => (
    <Routes>
        {/* Account Route */}
        <Route path="/" element={<AccountIndex/>}/>
        <Route path="/signin" element={<LoginPage />} />
        <Route path="/signup/" element={<SignUpPage />} />
        <Route path="/reset_password/" element={<ResetPassword />} />
        <Route path='/manage-account/profile' element={<ProtectedRoute><AccontProfile /></ProtectedRoute>} />
        <Route path='/manage-account/security' element={<ProtectedRoute><SecuritySettings /></ProtectedRoute>} />
        <Route path='/manage-account/notifications' element={<ProtectedRoute><NotificationSettings /></ProtectedRoute>} />
        <Route path='/manage-account/support' element={<ProtectedRoute><AcctSupport /></ProtectedRoute>} />
    </Routes>
);

export default AppRoutes;
