import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || 'mysecretkey';

export const encryptData = (data) => {
    const encrypted = CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
    // Base64 encode before storing
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
};

export const decryptData = (cipherText) => {
    try {
        // Decode Base64 and decrypt
        const decoded = CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Utf8);
        const bytes = CryptoJS.AES.decrypt(decoded, SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
};