import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/account/AuthStyles.css';
import { apiverfiyOTP, createNewPassword, forgotPassword } from '../../services/account';
import validatePassword from '../../utils/passwordValidate';
import { FaArrowLeft, FaCheckSquare } from 'react-icons/fa';

const ResetPassword = () => {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [verificationCode, setVerificationCode] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await forgotPassword(email, 'verify_email');
            setStep(1);
            setSuccess(response.message);
            setTimeout(() => {
                setSuccess('');
            }, 10000); // Timeout duration in milliseconds
        } catch (error) {
            setError(error.error);
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds

        } finally {
            setLoading(false);
        }
        setLoading(false);

    }
    const handleSendOTP = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await apiverfiyOTP(email, verificationCode, 'verify_otp', { email });
            setStep(2);
        } catch (error) {
            setError(error.error);
            setVerificationCode('');
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds

        } finally {
            setLoading(false);
        }
        setLoading(false);

    }

    const handlePassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await createNewPassword(password, confirmPassword, 'set_new_password', { email });
            setStep(3);
        } catch (error) {
            setError(error.error);
            setPassword('');
            setConfirmPassword('');
            // Clear the error message after 3 seconds
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        } finally {
            setLoading(false);
        }

    }
    const passwordValidation = validatePassword(password);

    return (
        <div className="auth-container d-flex justify-content-center align-items-center vh-100">
            <div className="card login-card">
                {
                    step === 0 ? null :
                        <div className='text-start m-0 text-primary btn-back-responsive' onClick={() => setStep(step - 1)}>
                            <FaArrowLeft size={15} />
                            <span> Back</span>
                        </div>
                }
                {loading && <div className="loading-overlay">
                    <div className="loader">Authenticating</div>
                </div>}
                <div className='card-body login-card-body'>
                    {/* Left Side  Logo and welcome text*/}
                    <div className='col-md-6 welcome-section'>
                        <div className='text-center'>
                            <img
                                src="/assets/img/echowavs-no-bg.png"
                                alt="Company Logo"
                                className="img-fluid mx-auto d-block mb-3"
                                style={{ maxWidth: '200px' }}
                            />
                            <div className='welcome-text'>
                                <h3>Welcome to our platform</h3>
                                <p>
                                    We're excited to have you here. Please Reset Your Password to continue exploring our services
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Right Side Form */}
                    <div className='col-md-6 login-section'>
                        <h4 className='text-start fs-1 text-login mb-4'>Reset Password</h4>
                        <div>
                            {step === 0 && (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mb-3">
                                        <input
                                            type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            className="form-control"
                                            placeholder="Please Enter your Registered Email Address"
                                            required
                                        />
                                    </div>
                                    {error && <p style={{ color: 'red', marginTop: -10 + "px" }}>{error}</p> || null}
                                    <div className='continue-btn mt-5'>
                                        <button className='btn btn-primary btn-back' onClick={() => window.location.href = '/signin/'}>
                                            <span>Cancel</span>
                                        </button>
                                        <button type="submit" className="btn btn-primary float-end">Verify Email</button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div>
                            {step === 1 && (
                                <form onSubmit={handleSendOTP}>
                                    <div className="form-group mb-3">
                                        <input
                                            type="text"
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            value={verificationCode}
                                            className="form-control"
                                            placeholder="Verify OTP"
                                            required
                                        />
                                        <p className='text-end echowavs-color-orange'>Didn't Receive code? <a href='#' onClick={handleSubmit}>Resend</a></p>
                                    </div>
                                    {error && <p style={{ color: 'red', marginTop: -10 + "px" }}>{error}</p> || null}
                                    {success && <p style={{ color: 'green', marginTop: -10 + "px" }}>{success}</p>}
                                    <div className='continue-btn mt-5'>
                                        <button className='btn btn-primary btn-back' onClick={() => setStep(step - 1)}>
                                            <FaArrowLeft size={15} />
                                            <span> Back</span>
                                        </button>
                                        <button type="submit" className="btn btn-primary float-end">Verify</button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div>
                            {step === 2 && (
                                <form onSubmit={handlePassword}>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            className="form-control"
                                            placeholder="New Password"
                                            required
                                        />
                                        <p className='text-start m-0 password-description' style={{ color: 'grey', marginTop: -20 + "px" }}>
                                            <small className={`${passwordValidation.minLengthCheck ? 'text-success' : 'text-danger'}`}>At least 8 characters</small> *
                                            <small className={`${passwordValidation.uppercaseCheck ? 'text-success' : 'text-danger'}`}>At least one uppercase</small> *
                                            <small className={`${passwordValidation.lowercaseCheck ? 'text-success' : 'text-danger'}`}>At least one lowercase</small> *
                                            <small className={`${passwordValidation.digitCheck ? 'text-success' : 'text-danger'}`}>At least one digit</small> *
                                            <small className={`${passwordValidation.specialCharCheck ? 'text-success' : 'text-danger'}`}>At least one special character</small> *
                                            <small className={`${passwordValidation.sequenceCheck ? 'text-success' : 'text-danger'}`}>Cannot contain common sequences (e.g., 123, abc)</small>
                                        </p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input
                                            type="password"
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            value={confirmPassword}
                                            className="form-control"
                                            placeholder="Confirm New Password"
                                            required
                                        />
                                    </div>
                                    {error && <p style={{ color: 'red', marginTop: -10 + "px" }}>{error}</p> || null}
                                    <div className='continue-btn mt-5'>
                                        <button className='btn btn-primary btn-back' onClick={() => setStep(step - 1)}>
                                            <FaArrowLeft size={15} />
                                            <span> Back</span>
                                        </button>
                                        <button type="submit" className="btn btn-primary float-end">Submit</button>
                                    </div>
                                </form>
                            )}
                        </div>
                        <div>
                            {step === 3 && (
                                <>
                                    <p className='text-success'>Password reset Successful, You can now Click on signin to Continue</p>
                                    <h1>
                                        <FaCheckSquare size={50} color='green' />
                                    </h1>

                                    <button onClick={() => window.location.href = '/signin/'} className="form-control btn btn-primary mt-5">Signin</button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
