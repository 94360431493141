import { eraseCookie, getCookie, setCookie } from "../utils/cookieUtils";
import { decryptData, encryptData } from "../utils/encryptionUtils";
import API from "./api";
import axios from 'axios';


const token = getCookie('access_token');
const decryptToken = token ? decryptData(token) : null

export const login = async (username, password) => {
    try {
        const response = await API.post(`/account/signin/`, { username, password });
        // Encrypt tokens before saving
        const encryptedAccessToken = encryptData(response.data.access);
        const encryptedRefreshToken = encryptData(response.data.refresh);

        setCookie('access_token', encryptedAccessToken);
        setCookie('refresh_token', encryptedRefreshToken);

        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const send2FAOtp = async (user_id) => {
    try {
        const response = await API.post(`account/send-otp/`, { user_id });
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const verify2FAOtp = async (user_id, otp) => {
    try {
        const response = await API.post(`account/verify/otp/`, {
            user_id, otp
        });
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const apiVerify2FA = async (user_id, otp) => {
    try {
        const response = await API.post(`account/verify-otp/`, { user_id, otp });
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
// Function to initiate signup and send OTP
export const signup = async (username, email, step) => {
    try {
        const response = await API.post(`account/signup/`, { username, email, step });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
};

// Function to verify OTP and complete registration
export const verifyOtp = async (email, otp, step, userData) => {
    try {
        const response = await API.post(`account/signup/`, { ...userData, email, otp, step });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
};

export const firstLastName = async (firstName, lastName, step, userData) => {
    try {
        const response = await API.post(`account/signup/`, { ...userData, first_name: firstName, last_name: lastName, step: step });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const addressInfo = async (country, state, addressLine, step, userData) => {
    try {
        const response = await API.post(`account/signup/`, {
            ...userData, country, state,
            address_line: addressLine,
            step: step,
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
export const phoneNumberV = async (countryCode, phoneNumber, step, userData) => {
    try {
        const response = await API.post(`account/signup/`, {
            ...userData, country_code: countryCode, phone_number: phoneNumber,
            step: step,
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }

}

export const passwordV = async (password, confirmPassword, step, inviteCode, device_id, userData) => {
    try {
        const response = await API.post(`account/signup/?inviteCode=${inviteCode}`, {
            ...userData, password: password, confirm_password: confirmPassword, device_id, step: step
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
// Function to send OTP for forgot password
export const forgotPassword = async (email, step) => {
    try {
        const response = await API.post(`account/reset_password/`, { email, step });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
};
// Function to send OTP for forgot password
export const apiverfiyOTP = async (email, otp, step, userData) => {
    try {
        const response = await API.post(`account/reset_password/`, {
            email, otp, step, ...userData
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
// Function to Create New Password
export const createNewPassword = async (password, confirmPassword, step, userData) => {
    try {
        const response = await API.post(`account/reset_password/`, {
            password, confirm_password: confirmPassword, step, ...userData,
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const fetchUsers = async () => {
    try {
        const token = getCookie('access_token')
        const response = await API.get(`/users/`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }

        );
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
export const managePersonalInfo = async (id, data) => {
    try {
        const response = await API.put(`/account/user/manage/personal-info/${id}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${decryptToken}`
                }
            }
        );
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
export const manageAddressInfo = async (id, data) => {
    try {
        const response = await API.put(`/account/user/manage/address-info/${id}/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${decryptToken}`
                }
            }
        );
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
export const manageSecurity = async (id, password) => {
    try {
        const response = await API.put(`/account/user/manage/security/${id}/`,
            {
                password: password,
            },
            {
                headers: {
                    Authorization: `Bearer ${decryptToken}`
                }
            }
        );
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const manageProfilePic = async (id, profile_pic) => {
    try {

        const formData = new FormData();
        formData.append('profile_pic', profile_pic);

        const response = await axios.put(
            `https://api.echowavs.com.ng/account/user/manage/profile-pic/${id}/`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${decryptToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
};

export const getUser = async () => {
    try {
        const token = getCookie('access_token')
        const decryptToken = token ? decryptData(token) : null;
        const response = await API.get(`account/user/`,
            {
                headers: {
                    Authorization: `Bearer ${decryptToken}`
                }
            }

        );
        return response.data
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
export const Enable2FA = async (enabled) => {
    try {
        const response = await API.post(`account/toggle-2fa/`,
            {
                enabled
            }
        );
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
export const EnableUpdate = async (enabled) => {
    try {
        const response = await API.post(`account/toggle-update/`,
            {
                enabled
            }
        );
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}
export const getAllCountries = async () => {
    try {
        const response = await API.get(`account/country/`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const getAllStateCountry = async (country) => {
    try {
        const response = await API.get(`account/state/country/?country=${country}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const getAllCountryCodes = async () => {
    try {
        const response = await API.get(`account/country-code/`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const validateUsername = async (username) => {
    try {
        const response = await API.get(`account/validate/username/?username=${username}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }

}
export const validateEmail = async (email) => {
    try {
        const response = await API.get(`account/validate/email/?email=${email}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }

}

export const validatePhone = async (phone) => {
    try {
        const response = await API.get(`account/validate/phone/?phone=${phone}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const SendSupport = async (name, email, message) => {

    try {
        const response = await API.post('/sub/help-support/',
            {
                name: name,
                email: email,
                message: message
            }
        )
        return response;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }

}

export const getUserWallet = async () => {
    try {
        const response = await API.get('/sub/wallet/');
        return response
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
}

export const logout = () => {
    eraseCookie('access_token');
    eraseCookie('refresh_token');
    window.location.href = '/signin'; // Redirect to login page
}