import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { login as apiLogin, apiVerify2FA, send2FAOtp } from './account';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import API from "./api";
import { decryptData, encryptData } from '../utils/encryptionUtils';
import { getCookie, setCookie } from '../utils/cookieUtils';

// Function to refresh the access token
export const refreshAccessToken = async () => {
    try {
        const refreshToken = getCookie('refresh_token');
        const decryptRefreshToken = refreshToken ? decryptData(refreshToken) : null;
        if (!decryptRefreshToken) {
            console.log('No refresh token found');
            return null;
        }

        const response = await API.post('/account/refresh/', { refresh: decryptRefreshToken });
        const encrypteAccessToken = encryptData(response.data.access)
        setCookie('access_token', encrypteAccessToken);
        return encrypteAccessToken;
    } catch (error) {
        console.log("Error refreshing token: ", error);
        throw error;
    }
};

export const isTokenValid = () => {
    const token = getCookie('access_token');
    const decrypToken = token ? decryptData(token) : null;
    if (!decrypToken) return false;

    try {
        const { exp } = jwtDecode(decrypToken);
        return Date.now() < exp * 1000; // Compare with current timestamp
    } catch (error) {
        return false; // Invalid token format
    }
};

export const AllowAnyRoute = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const authenticated = isTokenValid();

        // If the user is authenticated and tries to access login or signup, redirect to home
        if (authenticated && (location.pathname === '/login' || location.pathname === '/signup')) {
            navigate('/', { replace: true });
        }

        // If the user is not authenticated and tries to access a protected route, redirect to login
        if (!authenticated && location.pathname !== '/login' && location.pathname !== '/signup') {
            navigate('/login', { replace: true });
        }
    }, [navigate, location]);

    // Render the children components
    return <>{children}</>;
};

// ProtectedRoute component to protect routes
export const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const isAuthenticated = isTokenValid();

    useEffect(() => {
        if (isAuthenticated) {
            refreshAccessToken();
        }
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        const signInURL = `https://accounts.echowavs.com.ng/signin?redirect=${encodeURIComponent(window.location.href)}`;
        window.location.href = signInURL;
        return null; // Don't render anything while redirecting
    }

    return children;
};

export const useLogin = () => {
    const [userId, setUserId] = useState(null); // New state to store user_id
    // Example: After successful sign-in in your sign-in React app
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirect');

    const handleLogin = async (username, password, setError, setSuccess, setRequires2FA) => {

        try {
            // Call the API login function
            const response = await apiLogin(username, password);

            // Check if 2FA is required
            if (response.two_factor_required) {
                // Store the user_id for 2FA verification
                setUserId(response.user_id);
                setRequires2FA(true);
                setSuccess(response.message);
                setTimeout(() => {
                    setSuccess('');
                }, 6000); // Timeout duration in milliseconds

            } else {
                // Redirect to the intended page on successful login
                window.location.href = redirectUrl || 'https://echowavs.com.ng';
            }
        } catch (error) {
            // Set the error message if login fails
            setError(error.detail);
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }
    };

    const handle2FAVerification = async (code, setError) => {
        try {
            // Send the verification code to the API
            const response = await apiVerify2FA(userId, code); // Pass stored userId to the API

            const encrypteAccessToken = encryptData(response.access);
            const encryptedRefreshToken = encryptData(response.refresh);

            // Store the tokens (use setCookie)
            setCookie('refresh_token', encryptedRefreshToken);
            setCookie('access_token', encrypteAccessToken);

            // Redirect to the intended page on successful login
            window.location.href = redirectUrl || '/';
        } catch (error) {
            console.log(error);
            setError(error.response ? error.response.data : error.detail);
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }
    };

    const resend2FACode = async (setError, setSuccess) => {
        try {
            // Send the verification code to the API
            const response = await send2FAOtp(userId); // Pass stored userId to the API
            setSuccess(response.message);
            setTimeout(() => {
                setSuccess('');
            }, 6000); // Timeout duration in milliseconds
        } catch (error) {
            setError(error.response ? error.response.data : error.detail);
            setTimeout(() => {
                setError('');
            }, 3000); // Timeout duration in milliseconds
        }
    }

    return { handleLogin, handle2FAVerification, resend2FACode };
};


