import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import './sidebar.css';
import { getUserWallet } from '../../services/account';
import { getUser } from '../../services/account';


const Sidebar = ({ sidebarOpen, toggleSidebar, navItems }) => {
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [userWallet, setUserWallet] = useState(null);
    const [userData, setUserData] = useState(null);
    const [greeting, setGreeting] = useState('');

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };

    const fetchUserWallet = async () => {
        try {
            const res = await getUserWallet();
            setUserWallet(res.data);
        } catch (error) {
            console.error("Error fetching wallet:", error);
        }
    };

    const fetchUser = async () => {
        try {
            const res = await getUser();
            setUserData(res);
        } catch (error) {
            console.error("Error fetching user:", error);
        }
    };

    useEffect(() => {
        const currentHour = new Date().getHours();
        setGreeting(currentHour < 12 ? "Good Morning" : currentHour < 18 ? "Afternoon Dear," : "Evening Dear");
    }, []);

    useEffect(() => {
        fetchUserWallet();
        fetchUser();
    }, []);

    return (
        <aside className={`sidebar fixed-start ${sidebarOpen ? 'open' : 'closed'}`}>
            <div className='sidebar-header'>
                <span className='welcome-text'>{greeting}</span>
                <span className='bar-icon'>
                    <FaBars size={28} color='white' onClick={toggleSidebar} />
                </span>
            </div>
            <div className="profile-section text-center">
                <img
                    src={userData?.profile_pic || '/assets/img/echowavs-logo.png'}
                    alt="User Profile"
                    className={`profile-pic ${sidebarOpen ? 'open' : 'closed'}`}
                />
                {sidebarOpen && userData && (
                    <>
                        <h4 className="username">{userData.username}</h4>
                        {userWallet && (
                            <>
                                <p className="balance">Wallet Balance: <span style={{ color: "#f58025" }}>₦{userWallet.balance}</span></p>
                                <p className="balance">Wallet ID: <span style={{ color: "#f58025" }}>{userWallet.wallet_transaction_number}</span></p>
                            </>
                        )}
                    </>
                )}
            </div>
            <ul className="nav flex-column">
                {navItems.map((item, index) => (
                    <li key={index} className="nav-item">
                        {!item.subLabel ? (
                            <NavLink to={item.path} className="nav-link" activeClassName="active">
                                <span className='me-2'>{item.icon}</span>{sidebarOpen && item.label}
                            </NavLink>
                        ) : (
                            <div>
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={() => toggleDropdown(index)}
                                    aria-expanded={openDropdownIndex === index}
                                >
                                    {item.icon}{sidebarOpen && item.label}
                                    {sidebarOpen && (
                                        <i className={`bi ${openDropdownIndex === index ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                                    )}
                                </a>
                                {sidebarOpen && openDropdownIndex === index && (
                                    <ul className="dropdown-menu show">
                                        {item.subLabel.map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                <NavLink to={subItem.path} className="dropdown-item" activeClassName='active'>
                                                    {subItem.icon} {subItem.label}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </aside>
    );
};

export default Sidebar;
