import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { logout } from '../../services/account';
import './topnav.css'

const TopNav = ({ toggleSidebar, displayTextHeader = true }) => {
    return (
        <nav className="navbar fixed-top navbar-light top-nav">
            <div>
                <span className='topnave-bar-icon' style={{ marginLeft: 10 + "px" }}>
                    <FaBars size={28} color='white' onClick={toggleSidebar} />
                </span>
            </div>
            <div className="d-flex justify-content-bn align-items-center">
                {/* Moving Text Container */}
                <div className="attention-text" style={{ display: `${displayTextHeader ? 'block' : 'none'}` }}>
                    <p>Important Notice: Your account needs verification!</p>
                </div>
            </div>
            <span className="d-flex justify-content-end">
                <h3><a href='#' onClick={logout}>LogOut</a></h3>
            </span>
        </nav>
    );
};

export default TopNav;
