import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react'; // Add this line
import AppRoutes from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import getFingerprint from './utils/deviceIdUtils';



function App() {
  useEffect(() => {
    getFingerprint();
  }, [])
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
