import { useEffect, useState } from "react";
import AccountContent from "../../components/contents/AccountContent";
import "../../styles/account/ManageAccount.css";
import { EnableUpdate, getUser } from "../../services/account";
import { FaBell } from "react-icons/fa";
import Toast from '../../components/toast/toast';

const NotificationSettings = () => {
    const [isEditing, setIsEditing] = useState({
        update: false,
    });

    const [userDetail, setUserDetail] = useState(null);
    const [enableUpdate, setEnableUpdate] = useState(false);

    const [isToastOpen, setIsToastOpen] = useState(false);
    const [response, setResponse] = useState('');

    const handleToastClose = () => setIsToastOpen(false);
    // Fetch user data
    const fetchUser = async () => {
        try {
            const res = await getUser();
            setUserDetail(res);
            setEnableUpdate(res?.notify_on_update);
        } catch (error) {
            console.error("Error fetching user:", error);
        }
    };
    // Toggle Edit Mode
    const toggleEdit = (section) => {
        setIsEditing((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };
    // Handle 2FA Toggle
    const handleEnableUpdate = async () => {
        try {
            const res = await EnableUpdate(!enableUpdate);
            setEnableUpdate(!enableUpdate);
            setResponse(<span className="text-success">{res.data.message}</span>)
            setIsToastOpen(true);
            console.log(res);

        } catch (error) {
            setResponse(<span className="text-danger">{error.error || "An error Occured"}</span>)
            setIsToastOpen(true)
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <AccountContent content={
            <div className="main-container manage-account-wrapper">
                {isToastOpen && (
                    <Toast onClose={handleToastClose} message={response}>
                    </Toast>
                )}
                <main className="p-4">
                    <div className="security-settings-container">
                        {/* Updates Section */}
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5><FaBell /> Notify on Updates</h5>
                                <button className="btn btn-sm btn-primary" onClick={handleEnableUpdate}>
                                    {enableUpdate ? "Disable" : "Enable"}
                                </button>
                            </div>
                            <div className="card-body">
                                <p>
                                    <strong>Status:</strong>{" "}
                                    <span className={enableUpdate ? "text-success" : "text-danger"}>
                                        {enableUpdate ? "Enabled" : "Disabled"}
                                    </span>
                                </p>
                                {enableUpdate &&
                                    <div className="bg-info" style={{ margin: 0, height: 50 + 'px', padding: 10 + 'px', borderRadius: 50 + 'px', }}>
                                        <p className="text-start text-white">{enableUpdate && 'You will Now Receive Updates from Echowavs'}</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        } />
    );
};

export default NotificationSettings;
