import React, { useState } from 'react';
import "../../styles/account/ManageAccount.css";
import { FaWhatsappSquare, FaQuestionCircle, FaEnvelope, FaPhone } from 'react-icons/fa';
import { SendSupport } from '../../services/account';
import AccountContent from '../../components/contents/AccountContent';
import Loader from '../../components/loader/Loader';
import Toast from '../../components/toast/toast';

const AcctSupport = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isOpenToast, setIsOpenToast] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleSendMsg = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await SendSupport(name, email, message);
            setModalMessage(<span className='text-success'>Your message has been sent successfully!</span>);
            setIsOpenToast(true);
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            setModalMessage(<span className='text-danger'>Failed to send message. Please try again later.</span>);
            setIsOpenToast(true);
        }
        setLoading(false);
    };

    return (
        <AccountContent content={
            <div className="main-container manage-account-wrapper help-support">
                {loading && <Loader />}
                <h1 className='text-center mb-4'>Help & Support</h1>
                {/* Introduction */}
                <div className="card mb-4">
                    <div className="card-body text-center">
                        <p className="support-intro">
                            We're here to help! If you have any questions or need assistance, you've come to the right place.
                        </p>
                        <p>Contact us via email, phone, or WhatsApp for any inquiries.</p>
                    </div>
                </div>

                {/* FAQ Section */}
                <div className="card mb-4">
                    <div className="card-header">
                        <h2><FaQuestionCircle /> Frequently Asked Questions</h2>
                    </div>
                    <div className="card-body">
                        <ul className="faq-list">
                            <li>
                                <strong>How can I reset my password?</strong>
                                <p>Go to the login page and click on 'Forgot Password?' Follow the instructions to reset it.</p>
                            </li>
                            <li>
                                <strong>How do I contact customer support?</strong>
                                <p>You can reach us via email at abdulmumina535@gmail.com or call us at +(234) 9165661769.</p>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Contact Section */}
                <div className="card mb-4">
                    <div className="card-header">
                        <h2><FaEnvelope /> Contact Us</h2>
                    </div>
                    <div className="card-body">
                        <form className="contact-form" onSubmit={handleSendMsg}>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Your Name"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Your Email"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    placeholder="Your Message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                ></textarea>
                            </div>
                            <button className="btn btn-primary w-100" type="submit">
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>

                {/* Contact via WhatsApp */}
                <div className="text-center mt-4">
                    <p className='contact-whatsapp-text mb-2'>Contact us via WhatsApp</p>
                    <a href="https://wa.me/+2349165661769" target="_blank" rel="noopener noreferrer">
                        <FaWhatsappSquare className='contact-whatsapp' size={60} color='green' />
                    </a>
                </div>

                {/* Toast for Confirmation */}
                {isOpenToast && (
                    <Toast onClose={() => setIsOpenToast(false)} message={modalMessage}>
                    </Toast>
                )}
            </div>
        } />
    );
};

export default AcctSupport;
