import { useEffect, useState } from "react";
import AccountContent from "../../components/contents/AccountContent";
import "../../styles/account/ManageAccount.css";
import { Enable2FA, EnableUpdate, getUser, manageSecurity, send2FAOtp, verify2FAOtp } from "../../services/account";
import { FaEye, FaRegEye, FaLock, FaShieldAlt } from "react-icons/fa";
import Modal from '../../components/modal/Modal';
import Loader from "../../components/loader/Loader";
import Toast from "../../components/toast/toast";

const SecuritySettings = () => {
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState({
        password: false,
        twoFA: false,
    });
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [isVerifyOpenModal, setIsVerifyOpenModal] = useState(false);

    const [userDetail, setUserDetail] = useState(null);
    const [enable2FA, setEnable2FA] = useState(false);

    // State to toggle password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Password Fields
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [pin, setPin] = useState('');

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [response, setResponse] = useState('');
    const [resendTimeout, setResendTimeout] = useState(100); // 1 minutes

    const handleToastClose = () => setIsToastOpen(false);
    const handleVerifyCloseModal = () => setIsVerifyOpenModal(false);
    const handleVerifyOpenModal = () => setIsVerifyOpenModal(true);
    const handleCloseModal = () => setIsOpenModal(false);

    const handleResendOTP = async () => {
        if (resendTimeout > 0) return;
        setLoading(true);
        const otpSent = await send2FAOtp(userDetail?.id);
        if (otpSent) {
            setResendTimeout(100); // Reset to 1 minutes
        }
        setLoading(false);
    };

    useEffect(() => {
        if (resendTimeout > 0) {
            const timer = setTimeout(() => setResendTimeout(resendTimeout - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [resendTimeout]);

    // Fetch user data
    const fetchUser = async () => {
        try {
            const res = await getUser();
            setUserDetail(res);
            setEnable2FA(res?.two_factor_enabled);
        } catch (error) {
            console.error("Error fetching user:", error);
        }
    };

    // Toggle Edit Mode
    const toggleEdit = (section) => {
        setIsEditing((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    //Send OTP For Password
    const sendOTPForPassword = async () => {
        setLoading(true);
        try {
            const res = await send2FAOtp(userDetail?.id);
            setResponse(<span className='text-success'>{res.message}</span>)
            setIsToastOpen(true);
            setIsVerifyOpenModal(true);
            setLoading(false);
            return true;
        } catch (error) {
            setResponse(<span className='text-danger'>{error.error || "An Error Ocured, Please try again!."}</span>)
            setIsToastOpen(true)
            setLoading(false);
            return false;
        }
    }

    //Send OTP For 2FA
    const sendOTPFor2FA = async () => {
        setLoading(true);
        try {
            const res = await send2FAOtp(userDetail?.id);
            setResponse(<span className='text-success'>{res.message}</span>)
            setIsToastOpen(true);
            setIsOpenModal(true);
            setLoading(false);
            return true;
        } catch (error) {
            setResponse(<span className='text-danger'>{error.error || "An Error Ocured, Please try again!."}</span>)
            setIsToastOpen(true)
            setLoading(false);
            return false;
        }
    }

    const verifyOTPForPassword = async () => {
        setLoading(true);
        try {
            const res = await verify2FAOtp(userDetail?.id, pin);
            setResponse(<span className='text-success'>{res.message}</span>);
            setIsToastOpen(true);
            setLoading(false);
            setPin('');
            setIsVerifyOpenModal(false); // Close modal if successful
            toggleEdit('password');
            return true; // Return true without toggling edit
        } catch (error) {
            setPin('');
            setResponse(<span className='text-danger'>{error.error || "An Error Occurred, Please try again!."}</span>);
            setIsToastOpen(true);
            setLoading(false);
            return false;
        }
    };

    // Handle 2FA Toggle
    const verifyUpdate2FAOTP = async () => {
        setLoading(true);
        try {
            const res = await Enable2FA(!enable2FA);
            setEnable2FA(!enable2FA);
            setResponse(<span className='text-success'>{'Successfully updated 2FA'}</span>);
            setIsToastOpen(true);
            setVerificationCode('');
            setIsOpenModal(false); // Close modal
        } catch (error) {
            setResponse(<span className='text-danger'>{error.error || "An Error Occurred, Please try again!."}</span>);
            setIsToastOpen(true);
        }
        setLoading(false);
    };
    // Handle Password Change
    const handleChangePassword = async () => {
        setLoading(true);
        if (password === confirmPassword) {
            try {
                const res = await manageSecurity(userDetail?.id, password);
                setPassword("");
                setConfirmPassword("");
                setResponse(<span className='text-success'>{res.message}</span>);
                setIsToastOpen(true);
                toggleEdit('password')
            } catch (error) {
                setResponse(<span className='text-danger'>{error.error || "An Error Occurred, Please try again!."}</span>);
                setIsToastOpen(true);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <AccountContent content={
            <div className="main-container manage-account-wrapper">
                {loading && <Loader />}
                {/* Modal for password verification */}
                {isVerifyOpenModal && (
                    <Modal
                        title={'Verify OTP to Change Password'}
                        isOpen={isVerifyOpenModal}
                        onClose={handleVerifyCloseModal}
                        buttons={[
                            { label: 'Cancel', type: 'danger', onClick: handleVerifyCloseModal },
                            { label: loading ? "Verifying..." : 'Submit', type: 'primary', onClick: verifyOTPForPassword }

                        ]}
                    >
                        <div className="form-floating mb-1">
                            <input type='text'
                                id='pin'
                                className="form-control"
                                value={pin}
                                onChange={(e) => setPin(e.target.value)}
                                placeholder={`Enter OTP Sent to ${userDetail?.email}`}
                                autoFocus
                            />
                            <label htmlFor='pin' style={{ fontSize: 12 + "px" }}>Enter OTP Sent to <span className="text-success">{userDetail?.email}</span></label>
                            <p className='text-end' style={{ color: 'grey' }}>
                                <a href="#" onClick={handleResendOTP} disabled={resendTimeout > 0}>
                                    {resendTimeout > 0 ? `Resend in ${resendTimeout}` : 'Send Now'}
                                </a>
                            </p>

                        </div>
                    </Modal>
                )}

                {isToastOpen && (
                    <Toast onClose={handleToastClose} message={response}>
                    </Toast>
                )}
                <main className="p-4">
                    <div className="security-settings-container">
                        {/* Password Section */}
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5><FaLock /> Password Settings</h5>
                                {
                                    isEditing.password ?
                                        <button className="btn btn-sm btn-primary" onClick={() => toggleEdit('password')}>
                                            Cancel
                                        </button>
                                        :
                                        <button className="btn btn-sm btn-primary" onClick={sendOTPForPassword}>
                                            Edit
                                        </button>

                                }
                            </div>
                            <div className="card-body">
                                {!isEditing.password ? (
                                    <p><strong>Current Password:</strong> ********</p>
                                ) : (
                                    <>
                                        <div className="mb-3">
                                            <label className="float-start">New Password</label>
                                            <div className="input-group">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Enter new password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <FaRegEye /> : <FaEye />}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="float-start">Confirm Password</label>
                                            <div className="input-group">
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder="Confirm new password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                >
                                                    {showConfirmPassword ? <FaRegEye /> : <FaEye />}
                                                </button>
                                            </div>
                                            {password !== confirmPassword && (
                                                <p className="text-danger">Passwords do not match!</p>
                                            )}
                                        </div>
                                        <button
                                            className="btn btn-success float-start"
                                            disabled={password !== confirmPassword}
                                            onClick={handleChangePassword}
                                        >
                                            Save Password
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* Two-Factor Authentication Section */}
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5><FaShieldAlt /> Two-Factor Authentication</h5>
                                <button className="btn btn-sm btn-primary" onClick={sendOTPFor2FA}>
                                    {enable2FA ? "Disable" : "Enable"}
                                </button>
                            </div>
                            <div className="card-body">
                                <p>
                                    <strong>Status:</strong>{" "}
                                    <span className={enable2FA ? "text-success" : "text-danger"}>
                                        {enable2FA ? "Enabled" : "Disabled"}
                                    </span>
                                </p>
                            </div>
                        </div>

                        {/* Modal for 2FA Verification */}
                        {isOpenModal && (
                            <Modal
                                isOpen={isOpenModal}
                                onClose={() => setIsOpenModal(false)}
                                title="Enable 2FA"
                                buttons={[
                                    { label: 'Cancel', type: 'danger', onClick: handleCloseModal },
                                    { label: loading ? "Verifying..." : 'Submit', type: 'primary', onClick: verifyUpdate2FAOTP }
                                ]}
                            >
                                <div className="form-floating mb-1">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={`Enter OTP Sent to ${userDetail?.email}`}
                                        value={verificationCode}
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                        autoFocus
                                    />
                                    <label htmlFor='pin' style={{ fontSize: 12 + "px" }}>Enter OTP Sent to <span className="text-success">{userDetail?.email}</span></label>
                                    <p className='text-end'>
                                        <a href="#" onClick={handleResendOTP} disabled={resendTimeout > 0} className={``}>
                                            Resend in {resendTimeout > 0 ? resendTimeout : 'Send Now'}s
                                        </a>
                                    </p>
                                </div>
                            </Modal>
                        )}
                    </div>
                </main>
            </div>
        } />
    );
};

export default SecuritySettings;
